let getMonthRange = (dateStart, dateEnd) => {
  return (
    dateEnd.getMonth() -
    dateStart.getMonth() +
    12 * (dateEnd.getFullYear() - dateStart.getFullYear())
  );
};
//
let parseObjectWithIdToString = object => {
  let clone = JSON.parse(JSON.stringify(object));
  for (const key in clone) {
    if (Object.hasOwnProperty.call(clone, key) && clone[key]?.id) {
      clone[key] = clone[key].id;
    }
  }
  return clone;
};
let parseDateToISO = date => {
  return new Date(date).toISOString().substring(0, 10);
};
let getLastDayDateOfMonth = date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59);
};
let getFirstDayDateOfMonth = date => {
  return new Date(date.getFullYear(), date.getMonth(), 1, 12);
};
let getFollowingMonth = count => date => {
  return new Date(date.getFullYear(), date.getMonth() + count, 15); // возвращает дату с серединой месяца
};
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
let pipe = (...fns) => x => fns.reduce((v, f) => f(v), x); // https://www.freecodecamp.org/news/pipe-and-compose-in-javascript-5b04004ac937/
let toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const buildFlights = dates => {
  // Строим флайты зная общий период, работает по принципу, что 1 фалйт равен 1 календарному месяцу,
  // т.е. даже если флайт начинается в середине месяца, закончится он в его конце, либо в конце периода,
  // если это последний месяц из  помесячной разбивки всего периода.

  dates = Object.assign({}, dates);
  let start = new Date(dates.from),
    end = new Date(dates.to);
  let monthRange = getMonthRange(start, end);
  const flights = [];

  for (let i = 0; i <= monthRange; i++) {
    const index = i;
    let flight = {
      date_start: "",
      date_end: ""
    };
    switch (index) {
      case 0:
        {
          // если первый месяц всего периода
          flight.date_start = parseDateToISO(start);
          flight.date_end = pipe(getLastDayDateOfMonth, parseDateToISO)(start);
        }
        break;
      case monthRange:
        {
          // если последний месяц всего периода
          flight.date_start = pipe(getFirstDayDateOfMonth, parseDateToISO)(end);
          flight.date_end = parseDateToISO(end);
        }
        break;
      default:
        {
          flight.date_start = pipe(
            getFollowingMonth(index),
            getFirstDayDateOfMonth,
            parseDateToISO
          )(start);
          flight.date_end = pipe(
            getFollowingMonth(index),
            getLastDayDateOfMonth,
            parseDateToISO
          )(start);
        }
        break;
    }
    flights.push(flight);
  }
  return flights;
};
function download(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}
function useGeo() {
  const sortGeo = items => {
    return items.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  const removeBadGeo = (items, badItems) => {
    return items.filter(item => !~badItems.indexOf(item.name));
  };

  const addBeforeGeo = (items, mainItems) => {
    let mainItemsGeo = mainItems.map(mainItem => ({
      id: null,
      parentID: null,
      name: mainItem
    }));
    const withOutMainItems = items.filter(item => {
      const excludeIDs = ["12177135519009551802", "9052863180360295829"];
      const tempFix = ~excludeIDs.indexOf(item.id);
      const itsMain = Boolean(~mainItems.indexOf(item.name)) && !tempFix;
      if (itsMain) {
        mainItemsGeo = mainItemsGeo.map(mainItemGeo => {
          if (mainItemGeo.name === item.name) {
            mainItemGeo.id = item.id;
            mainItemGeo.parentID = item.parentID;
          }
          return mainItemGeo;
        });
      }
      return !itsMain;
    });
    return [
      ...mainItemsGeo.filter(item => Boolean(item.id)),
      ...withOutMainItems
    ];
  };

  return { sortGeo, removeBadGeo, addBeforeGeo };
}

const formatDate = (date, options = { withTime: false, separator: "-" }) => {
  const formatNumber = number => (number < 10 ? "0" + number : number);
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const time = `${formatNumber(date.getHours())}:${formatNumber(
      date.getMinutes()
    )}`;

    const separator = options.separator ? options.separator : "-";
    let formattedDate = `${formatNumber(day)}${separator}${formatNumber(
      month
    )}${separator}${year}`;

    if (options.withTime) {
      formattedDate += `, ${time}`;
    }

    return formattedDate;
  } else {
    return null;
  }
};

export {
  buildFlights,
  parseDateToISO,
  pipe,
  debounce,
  useGeo,
  toBase64,
  download,
  groupBy,
  parseObjectWithIdToString,
  formatDate
};
